<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Header from '../src/components/Header.vue'
import Footer from '../src/components/Footer.vue'
import 'element-ui/lib/theme-chalk/display.css';
export default {
  components:{Header,Footer},
  mounted() {
    document.addEventListener('touchstart', this.handleTouchStart, false);
    // 监听触摸移动事件
    document.addEventListener('touchmove', this.handleTouchMove, false);
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     // 在路由进入前滚动到顶部
  //     console.log('111')
  //     window.scrollTo(0, 0);
  //   });
  // },
  // beforeRouteUpdate(to, from, next) {
  //   if(to.query.type) this.menuType = to.query.type
  //   // 在当前路由改变，但是该组件被复用时滚动到顶部
  //   console.log('222')
  //   this.scrollToTop();
  //   next();
  // },
  methods: {
    // scrollToTop() {
    //   console.log('ddddd')
    //   window.scrollTo(0, 0);
    // },
    handleTouchStart(event) {
      // 记录触摸开始时的触点数量
      this.startNum = event.touches.length;
    },
    handleTouchMove(event) {
      // 如果是双指同时移动，阻止默认行为防止页面缩放
      if (event.touches.length > 1) {
        event.preventDefault();
      }
      // 如果触点数量改变，也阻止默认行为
      if (this.startNum !== event.touches.length) {
        event.preventDefault();
      }
    }
  }
}
</script>

<style lang="scss">
@import "assets/css/common.css";
@import "assets/css/animate.css";
@import "assets/iconfont/iconfont.css";

@import "assets/css/screen.css";
</style>
