<template>
 <div>
   <el-row type="flex" class="header container"  justify="space-between">
     <el-col :span="15">
       <div class="header-left">
         <img src="../assets/img/common/logo.png" alt="" @click="$goPage('/')">
         <router-link class="h-text hidden-sm-and-down" to="/home">首页</router-link>
         <router-link class="h-text hidden-sm-and-down" to="/product">产品</router-link>
         <router-link class="h-text hidden-sm-and-down" to="/service">服务</router-link>
         <router-link class="h-text hidden-sm-and-down" to="/news">资讯</router-link>
       </div>
     </el-col>
     <el-col :span="9">
       <div class="header-right  hidden-sm-and-down">
         <router-link class="header-right-menu1 h-text" to="/aboutUs">关于我们</router-link>
         <div class="iconfont icon-iosworldoutline h-text"></div>
         <el-dropdown @command="langChange">
           <div class="header-right-menu3 h-text"><span style="width: 20px">{{ lang }}</span><i class="iconfont icon-xiala"></i></div>
           <el-dropdown-menu slot="dropdown">
             <el-dropdown-item command="CN">CN</el-dropdown-item>
             <el-dropdown-item command="EN">EN</el-dropdown-item>
           </el-dropdown-menu>
         </el-dropdown>
       </div>
       <div class="header-right hidden-md-and-up"><div class="header-menu iconfont icon-caidan" @click="showMenu = true"></div></div>
     </el-col>
   </el-row>

   <el-drawer
       :visible.sync="showMenu" size="80%"
       direction="ttb">
    <div class="menu-box" @click="showMenu=false">
      <img class="menu-logo" src="../assets/img/common/logo.png" alt="">
      <router-link class="menu-item" to="/home">首页</router-link>
      <router-link class="menu-item" to="/product">产品</router-link>
      <router-link class="menu-item" to="/service">服务</router-link>
      <router-link class="menu-item" to="/news">资讯</router-link>
      <router-link class="menu-item" to="/aboutUs">关于我们</router-link>
      <div class="header-right">
        <div class="header-right-menu3 h-text" :class="lang == 'CN' ? 'on' : ''" @click="langChange('CN')"><img src="../assets/img/flag/zh.png" alt=""> CN</div>
        <div class="header-right-menu3 h-text" :class="lang == 'EN' ? 'on' : ''" @click="langChange('EN')"><img src="../assets/img/flag/en.png" alt=""> EN</div>
      </div>
    </div>
   </el-drawer>
 </div>
</template>

<script>
export default {
  name: "Header",
  data(){
    return{
      showMenu:false,
      lang:'CN'
    }
  },
  methods:{
    langChange(e){
      if(e == 'EN'){
        this.$message('英文站即将上线，敬请期待');
        return false
      }
      this.lang = e
    }
  }
}
</script>

<style scoped>

</style>
