<template>
  <div class="footer">
    <div class="container" v-if="$baseVal.isPc">
      <el-row class="close-info-box align-c" justify="space-between" type="flex">
        <el-col :lg="18" :md="18" :sm="24" :xl="18" :xs="24" class="close-info-left">
          <div v-animate-on-intersect class="close-info-nick">
            <img v-image-preview src="../assets/img/qr.png">
          </div>
          <div v-animate-on-intersect class="close-info-list-box">
            <div class="close-info-list"><span>电话</span><i class="iconfont icon-dianhua"></i><span class="big">023-67665596</span>
            </div>
            <div class="close-info-list"><span>手机</span><i class="iconfont icon-tel"></i><span class="big">17353266067</span>
            </div>
            <div class="close-info-list"><span>邮件</span><i
                class="iconfont icon-youjian"></i><span>service@mhkjcq.com</span></div>
            <div class="close-info-list"><span>地址</span><i
                class="iconfont icon-dizhi"></i><span>重庆市巴南区国际生物城</span></div>
          </div>
        </el-col>
        <el-col :lg="6" :md="6" :sm="24" :xl="6" :xs="24" class="close-info-right">期待您的联系！</el-col>
      </el-row>
      <div v-animate-on-intersect class="close-info-bottom">
        <div class="box1">平等自主·信任担当·共创共赢</div>
        <div class="box2"></div>
      </div>
    </div>
    <div class="container" v-else>
      <el-row class="close-info-box align-c" justify="space-between" type="flex">
        <el-col :lg="18" :md="18" :sm="24" :xl="18" :xs="24" class="close-info-left">
          <div v-animate-on-intersect class="close-info-nick">
            <img v-image-preview src="../assets/img/qr.png">
          </div>
          <div v-animate-on-intersect class="close-info-list-box">
            <div class="close-info-list"><i class="iconfont icon-dianhua"></i><span class="big">023-67665596</span>
            </div>
            <div class="close-info-list"><i class="iconfont icon-tel"></i><span class="big">17353266067</span>
            </div>
            <div class="close-info-list"><i
                class="iconfont icon-youjian"></i><span>service@mhkjcq.com</span></div>
            <div class="close-info-list"><i
                class="iconfont icon-dizhi"></i><span>重庆市巴南区国际生物城</span></div>
          </div>
        </el-col>
<!--        <el-col :lg="6" :md="6" :sm="24" :xl="6" :xs="24" class="close-info-right">期待您的联系！</el-col>-->
      </el-row>
      <div v-animate-on-intersect class="close-info-bottom">
        <div class="box1">平等自主·信任担当·共创共赢</div>
        <div class="box2"></div>
      </div>
    </div>
    <div v-if="$baseVal.isPc" class="footer-btm">
      <div class="part1">
        <el-row class="container flex-warp" justify="space-between" type="flex">
          <el-col :lg="8" :md="9" :sm="24" :xl="5" :xs="24" class="footer-btm-left">
            <img alt="" src="../assets/img/common/logo2.png">
            <div>开发先进工艺技术，创新生产设备，</div>
            <div>构建安全、清洁和智能的化工生产。</div>
          </el-col>
          <el-col :lg="15" :md="14" :sm="20" :xl="15" :xs="20">
            <div class="footer-service-line">
              <div class="footer-service">
                <router-link class="footer-service-title" to="/product">单元技术与设备</router-link>
                <router-link to="/product/page-rrjj">熔融结晶</router-link>
                <router-link to="/product/page-shjj">升华结晶</router-link>
                <router-link to="/product/page-fzzl">分子蒸馏</router-link>
              </div>
              <div class="footer-service">
                <router-link class="footer-service-title" to="/product">工艺技术</router-link>
                <router-link to="/product/page-ldctjj">锂电池添加剂</router-link>
                <router-link to="/product/page-qqsysw">氢氰酸衍生物</router-link>
                <router-link to="/product/page-sfhfczyhly">副产及三废资源化</router-link>
              </div>
              <div class="footer-service">
                <router-link class="footer-service-title" to="/service">服务</router-link>
                <router-link to="/service/page-detail?type=1">工艺开发与测试</router-link>
                <router-link to="/service/page-detail?type=2">工程设计</router-link>
                <router-link to="/service/page-detail?type=3">设备制造与集成</router-link>
                <router-link to="/service/page-detail?type=4">交付与现场服务</router-link>
              </div>
              <div class="footer-service">
                <router-link class="footer-service-title" to="/aboutUs">关于我们</router-link>
                <router-link to="/aboutUs">公司介绍</router-link>
                <router-link to="/news">资讯与动态</router-link>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="part2">
        <div class="d-flex-between flex-warp container">
          <div>Copyright © 2020-2024 重庆敏恒科技有限公司</div>
          <div class="d-flex align-c">
            <a href="https://beian.miit.gov.cn" target="_blank">渝ICP备2021011164号-1  </a>
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50011302222017" target="_blank">
            <img alt="" src="../assets/img/common/footer-icon.png">渝公网安备 50011302222017
              </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="footer-btm-wap footer-mobile">
      <div class="footer-content">
        <div class="cont-img"><img alt="" src="../assets/img/common/logo.png"></div>
        <div class="text">开发先进工艺技术，创新生产设备，<br>构建安全、清洁和智能的化工生产。</div>
      </div>
      <div class="copy-right-mobile">
        <div class="text1">Copyright © 2020-2024 重庆敏恒科技有限公司</div>
        <div class="text2 d-flex-center">
          <a href="https://beian.miit.gov.cn" style="color: rgb(255, 255, 255); text-decoration: none;" target="_blank">渝ICP备2021011164号-1 </a>
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50011302222017" rel="noreferrer" class="text2-link"  target="_blank">
            <img alt="" src="../assets/img/common/footer-icon.png" style="width: 14px; margin-right: 4px;"> 渝公网安备 50011302222017</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",

}
</script>

<style scoped>

</style>
